import "./_components.js";
import "./_functions.js";
import "./functions/burger.js";
const accs = document.querySelectorAll(".accordion-block");

if (accs.length > 0) {
  accs.forEach((acc) => {
    const button = acc.querySelector(".accordion");
    const panel = acc.querySelector(".panel");
    if (button && panel) {
      button.addEventListener("click", () => {
        acc.classList.toggle("active");
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  });
}
